div.DayPicker-Day {
  height: 45px;
  width: 45px;
  padding: 0px;
  border: 1px solid rgb(207, 207, 207);
  font-weight: 600;
}

div.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: white;
}

div.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: white;
}

div.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
  background-color: white;
}
